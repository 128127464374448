<template>
  <div>
     <Headers :viewParams="viewParams" />
    
    <div class="top-content" v-if="can_show == 0">
      <div class="page-title-box">
          <h4 class="page-title"><div class="lang" v-html="lan.feeManager"></div></h4>
        </div>
      <div class="content no_premision" v-html="lan.no_premision"></div>
    </div>
    <div class="top-content" v-if="can_show == 1">
      <div class="col-12">
        
        <div class="card-box">
          <div class="page-title-box">
            <h4 class="page-title"><div class="lang" v-html="lan.feeManager"></div></h4>
          </div>
          <list-table-manager :viewParams="viewParams" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<style lang="scss" scoped>
.fee-manager{
padding-top: 150px;
}
</style>
<script>
import Headers from '@/components/Headers.vue'
import { inject } from 'vue';
import Footer from '@/components/Footer.vue'
import ListTableManager from '@/components/ListTableManager.vue'

export default {
  name: 'CorpList',
  components: {
    ListTableManager,Headers,Footer
  },
  data() {
    return {
      viewParams: {
        name: 'feemanager',
        apiUrl: 'creisFeeManagerList',
        articleView: 'feemanagerViews',
        articleParamId: 'ID',
        dataType:1,
      },
      cur_lan: localStorage.getItem("cur_lan") || "jp",
      can_show:0,
      lan:inject('global').creis_language_data,
    }
  }, mounted() {

    this.can_show=localStorage.getItem('groups')!="admin" ? 0:1;
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
    console.log(this.lan);
  },
  methods: {
    cannels:function(){
      this.$router.push('/')
    },
gotourl:function(url){
        this.$router.push(url);
    },
  },
  watch:{
  $route(){
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
   console.log(this.lan);
  }
},
}
</script>